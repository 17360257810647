import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaWhatsapp,
  FaEnvelope,
} from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-primary text-white py-4">
      <hr />
      <div className="max-w-6xl mx-auto text-center">
        <p className="text-accent mb-2">
          © 2024 Dream Jewels. All rights reserved.
        </p>
        <nav aria-label="Social media links">
          <ul className="flex justify-center space-x-4">
            <li>
              <a
                href="https://www.facebook.com/dreamjewelsuk"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-secondary"
                aria-label="Follow Dream Jewels on Facebook"
              >
                <FaFacebook className="h-5 w-5" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/dreamjewelsuk"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-secondary"
                aria-label="Follow Dream Jewels on Instagram"
              >
                <FaInstagram className="h-5 w-5" />
              </a>
            </li>
            <li>
              <a
                href="https://wa.me/+447466555525"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-secondary"
                aria-label="Chat with Dream Jewels on WhatsApp"
              >
                <FaWhatsapp className="h-5 w-5" />
              </a>
            </li>
            <li>
              <a
                href="mailto:dreamjewelsuk@gmail.com"
                className="hover:text-secondary"
                aria-label="Send an email to Dream Jewels"
              >
                <FaEnvelope className="h-5 w-5" />
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;

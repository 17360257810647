import React from "react";
import { FaWhatsapp } from "react-icons/fa";

const WhatsAppButton = () => {
  const whatsappNumber = "+447466555525"; // Replace with your WhatsApp number
  const message = "Hello! I would like to know more about your products."; // Default message

  const handleClick = () => {
    const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappUrl, "_blank", "noopener,noreferrer");
  };

  return (
    <button
      onClick={handleClick}
      className="fixed bottom-16 right-4 bg-green-500 text-white p-4 rounded-full shadow-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 flex items-center space-x-2"
      style={{ zIndex: 1000 }} // Ensure the button stays on top of other elements
      aria-label="Chat with us on WhatsApp"
      title="Chat with us on WhatsApp"
      type="button"
    >
      <FaWhatsapp size={24} aria-hidden="true" />
      <span>Chat with Us</span>
    </button>
  );
};

export default WhatsAppButton;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png"; // Ensure your logo.png is placed in the assets folder
import { FaChevronDown } from "react-icons/fa";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [timer, setTimer] = useState(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
    setSubmenuOpen(false);
    clearTimeout(timer);
  };

  const handleMouseEnter = () => {
    clearTimeout(timer);
    setTimer(
      setTimeout(() => {
        setSubmenuOpen(true);
      }, 300)
    );
  };

  const handleMouseLeave = () => {
    clearTimeout(timer);
    setTimer(
      setTimeout(() => {
        setSubmenuOpen(false);
      }, 1000)
    );
  };

  return (
    <nav className="bg-primary text-accent py-4" aria-label="Main navigation">
      <div className="max-w-6xl mx-auto flex items-center justify-between px-4">
        {/* Logo and Name - Side by Side */}
        <Link to="/" onClick={closeMenu} aria-label="Dream Jewels Homepage">
          <div className="flex items-center space-x-3"> {/* Flexbox to align logo and text horizontally */}
            <img
              src={logo}
              alt="Dream Jewels Logo"
              className="h-12 w-auto max-w-full"
              title="Dream Jewels"
            />
            {/* Dream Jewels text beside the logo */}
            <div className="text-white">
              <h1 className="text-xl font-bold leading-none">Dream Jewels</h1>
              {/* <h2 className="text-xl font-bold leading-none">Jewels</h2> */}
            </div>
          </div>
        </Link>

        <div className="hidden md:flex justify-center flex-grow space-x-8">
          <Link to="/" className="hover:text-secondary" title="Home">
            Home
          </Link>
          <Link to="/about" className="hover:text-secondary" title="About Us">
            About
          </Link>
          <div
            className="relative group"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <button
              className="hover:text-secondary focus:outline-none flex items-center"
              aria-haspopup="true"
              aria-expanded={submenuOpen}
              aria-controls="products-menu"
              title="Products"
            >
              Products
              <FaChevronDown
                className={`ml-2 transition-transform duration-300 ${
                  submenuOpen ? "transform rotate-180" : ""
                }`}
              />
            </button>
            <ul
              id="products-menu"
              className={`absolute left-0 mt-2 py-2 w-48 bg-white rounded-lg shadow-lg border border-gray-200 transition-opacity duration-300 ease-in-out z-50 ${
                submenuOpen ? "opacity-100 visible" : "opacity-0 invisible"
              }`}
              role="menu"
              aria-labelledby="products-menu"
            >
              <li>
                <Link
                  to="/products/engagement-rings"
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  onClick={closeMenu}
                  role="menuitem"
                >
                  Engagement Rings
                </Link>
              </li>
              <li>
                <Link
                  to="/products/wedding-bands"
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  onClick={closeMenu}
                  role="menuitem"
                >
                  Wedding Bands
                </Link>
              </li>
              <li>
                <Link
                  to="/products/vintage"
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  onClick={closeMenu}
                  role="menuitem"
                >
                  Vintage
                </Link>
              </li>
              <li>
                <Link
                  to="/products/accessories"
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  onClick={closeMenu}
                  role="menuitem"
                >
                  Accessories
                </Link>
              </li>
            </ul>
          </div>
          <Link
            to="/contact"
            className="hover:text-secondary"
            title="Contact Us"
          >
            Contact
          </Link>
        </div>
        <div className="hidden md:flex items-center">
          <Link
            to="/booking"
            className="bg-secondary hover:bg-gray-500 text-primary font-bold py-2 px-6 rounded-lg shadow-lg transform transition-transform hover:scale-105"
            title="Book an Appointment"
          >
            Book Appointment
          </Link>
        </div>
        <div className="md:hidden">
          <button
            id="menu-button"
            aria-label="Open menu"
            className="focus:outline-none text-white"
            onClick={toggleMenu}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              ></path>
            </svg>
          </button>
        </div>
      </div>
      <div className={`md:hidden ${menuOpen ? "block" : "hidden"} px-4`}>
        <Link
          to="/"
          className="block py-2 hover:bg-secondary"
          onClick={closeMenu}
          title="Home"
        >
          Home
        </Link>
        <Link
          to="/about"
          className="block py-2 hover:bg-secondary"
          onClick={closeMenu}
          title="About Us"
        >
          About
        </Link>
        <div>
          <button
            className="block py-2 w-full text-left hover:bg-secondary flex items-center"
            onClick={() => setSubmenuOpen(!submenuOpen)}
            aria-haspopup="true"
            aria-expanded={submenuOpen}
            aria-controls="mobile-products-menu"
            title="Products"
          >
            Products
            <FaChevronDown
              className={`ml-2 transition-transform duration-300 ${
                submenuOpen ? "transform rotate-180" : ""
              }`}
            />
          </button>
          <ul
            id="mobile-products-menu"
            className={`${submenuOpen ? "block" : "hidden"} px-4`}
            role="menu"
            aria-labelledby="mobile-products-menu"
          >
            <li>
              <Link
                to="/products/engagement-rings"
                className="block py-2 pl-4 hover:bg-secondary"
                onClick={closeMenu}
                role="menuitem"
              >
                Engagement Rings
              </Link>
            </li>
            <li>
              <Link
                to="/products/wedding-bands"
                className="block py-2 pl-4 hover:bg-secondary"
                onClick={closeMenu}
                role="menuitem"
              >
                Wedding Bands
              </Link>
            </li>
            <li>
              <Link
                to="/products/vintage"
                className="block py-2 pl-4 hover:bg-secondary"
                onClick={closeMenu}
                role="menuitem"
              >
                Vintage
              </Link>
            </li>
            <li>
              <Link
                to="/products/accessories"
                className="block py-2 pl-4 hover:bg-secondary"
                onClick={closeMenu}
                role="menuitem"
              >
                Accessories
              </Link>
            </li>
          </ul>
        </div>
        <Link
          to="/contact"
          className="block py-2 hover:bg-secondary"
          onClick={closeMenu}
          title="Contact Us"
        >
          Contact
        </Link>
        <Link
          to="/booking"
          className="block py-2 bg-secondary hover:bg-gray-500 text-primary font-bold py-2 px-6 rounded-lg shadow-lg transform transition-transform hover:scale-105"
          onClick={closeMenu}
          title="Book an Appointment"
        >
          Book Appointment
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
